
























































import { User } from '@/includes/types/User'
import UserLicense from '@/includes/logic/UserLicense'
import LicenseButton from '@/components/LicenseElements/LicenseButton.vue'
import LicenseExpireDate from '@/components/LicenseElements/LicenseExpireDate.vue'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import AListCustomItem from 'piramis-base-components/src/components/AListCustom/AListCustomItem.vue'
import AListCustom from 'piramis-base-components/src/components/AListCustom/AListCustom.vue'
import ClipboardCopyMixin from 'piramis-base-components/src/logic/helpers/ClipboardCopyMixin'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'
import { atSignedLogin, tgLoginLink } from "piramis-base-components/src/shared/utils/tgLoginHelpers";

import Component from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'

@Component({
  components: {
    LicenseExpireDate,
    LicenseButton,
    PageTitle,
    AListCustom,
    AListCustomItem
  }
})
export default class UserDashboard extends Mixins<UseFields, ClipboardCopyMixin>(UseFields, ClipboardCopyMixin) {
  get user(): User {
    return this.$store.getters.getCurrentUser(this.$route.params.id)
  }

  get userLogin():string {
    if (this.user.username) {
      return atSignedLogin(this.user.username)
    }

    return ''
  }

  get userLink():string {
    if (this.user.username) {
      return tgLoginLink(this.user.username)
    }

    return ''
  }

  get userlinkWithoutProtocol() {
    return this.userLink.replace('https://', '')
  }

  get userLicense() {
    return new UserLicense(this.user)
  }
}
